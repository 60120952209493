import { FragmentComponent } from '@/types/graphql'
import { DSTabs, TabContent } from '@zoe/ds-web'
import { ResponsiveImageAndContentBlockRecord, TabRecord, TextDescriptionRecord } from 'types'
import { ResponsiveImageAndContentBlock } from './ResponsiveImageAndContentBlock'

const fragment = `
  fragment TabFragment on TabRecord {
    __typename
    id
    titles {
      ... on TextDescriptionRecord {
        __typename
        id
        title
      }
    }
    content {
      ...ResponsiveImageAndContentBlockFragment 
    }
  }

  ${ResponsiveImageAndContentBlock.fragment}
`

export const Tabs: FragmentComponent<{}, TabRecord> = ({ record }) => {
  const processTabs = (
    titles: TextDescriptionRecord[],
    content: ResponsiveImageAndContentBlockRecord[],
  ): TabContent[] => {
    return titles.map((title, index) => {
      const tabContent = content[index]
      return {
        title: title.title ?? '',
        content: (
          <div>
            <ResponsiveImageAndContentBlock key={tabContent.id} record={tabContent} />
          </div>
        ),
      }
    })
  }

  const processedTabs = processTabs(record.titles, record.content)
  return <DSTabs tabs={processedTabs} />
}

Tabs.fragment = fragment
Tabs.recordName = 'TabRecord'
