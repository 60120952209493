import { FragmentComponent } from '@/types/graphql'
import { ResponsiveImageAndContentBlockRecord } from 'types'
import { TypographyBlock } from './Typography'
import { SimpleImageBlock } from './SimpleImage'
import { SpacerBlock } from './Spacer'
import styled from 'styled-components'
import { BREAKPOINTS } from '@/components/ds/breakpoints'
import { responsiveImageBlock } from '@/graphql/utils/commonBlocks'

const fragment = `
  fragment ResponsiveImageAndContentBlockFragment on ResponsiveImageAndContentBlockRecord {
    __typename
    id
    image {
      ... on SimpleImageRecord {
        __typename
        rounded
        image {
          ${responsiveImageBlock()}
        }
      }
    }
    content {
      ...TypographyBlockFragment
      ...SpacerBlockFragment
    }
}
`

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;

  @media (min-width: ${BREAKPOINTS.m}) {
    grid-template-columns: minmax(auto, 480px) 1fr;
  }

  .image-container {
    position: relative;
    aspect-ratio: 408 / 333;
    width: 100%;
    max-width: 408px;
    height: auto;
    margin: 0 auto;

    @media (min-width: ${BREAKPOINTS.m}) {
      margin: 0;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    text-align: center;

    @media (min-width: ${BREAKPOINTS.m}) {
      padding-top: 0;
      text-align: left;
    }
  }
`

export const ResponsiveImageAndContentBlock: FragmentComponent<{}, ResponsiveImageAndContentBlockRecord> = ({
  record,
}) => {
  const renderBlock = (block) => {
    switch (block.__typename) {
      case 'TypographyRecord':
        return <TypographyBlock key={block.id} record={block} />
      case 'SimpleImageRecord':
        return <SimpleImageBlock key={block.id} record={block} />
      case 'SpacerRecord':
        return <SpacerBlock key={record.id} record={block} />
      default:
        return null
    }
  }
  return (
    <StyledContainer>
      <div className="image-container">{record.image && renderBlock(record.image)}</div>
      <div className="content-container">{record.content?.map((block) => renderBlock(block))}</div>
    </StyledContainer>
  )
}

ResponsiveImageAndContentBlock.fragment = fragment
ResponsiveImageAndContentBlock.recordName = 'ResponsiveImageAndContentBlockRecord'
