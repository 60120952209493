import { FragmentComponent } from '@/types/graphql'
import React, { useEffect } from 'react'
import { useProducts } from '@/components/pages/membership/ProductsContext'
import { useCheckoutCountry } from '@/lib/hooks/useCheckoutCountry'
import { MembershipPdPPlansCards } from '@/elements/plans/Memberships/MembershipPriceCardPdp/MembershipPdPPlansCards'
import { DSText } from '@zoe/ds-web'
import { trackChoosePlanViewEvent } from '@/lib/gtm/gtmV2'

export type MembershipPlanRecord = {
  __typename: 'MembershipPlanRecord'
  id?: string
}

const fragment = `
  fragment MembershipPlanFragment on MembershipPlanRecord {
    __typename
    id
  }
`

export const MembershipPlanBlock: FragmentComponent<{}, MembershipPlanRecord> = () => {
  const products = useProducts()
  const { checkoutCountry } = useCheckoutCountry()
  useEffect(() => {
    trackChoosePlanViewEvent('Membership PDP')
  }, [])

  return checkoutCountry ? (
    <MembershipPdPPlansCards products={products} country={checkoutCountry} />
  ) : (
    <DSText variant="fluid-paragraph-400" as="p" align="center">
      ZOE membership is not available in your country yet
    </DSText>
  )
}

MembershipPlanBlock.fragment = fragment
MembershipPlanBlock.recordName = 'MembershipPlanRecord'
