import { FragmentComponent } from '@/types/graphql'
import { styled } from 'styled-components'
import { CarouselCardRecord } from 'types'
import { TypographyBlock } from './DesignSystem/Typography'
import { SimpleImageBlock } from './DesignSystem/SimpleImage'
import { SpacerBlock } from './DesignSystem/Spacer'
import { AvatarImageBlock } from './AvatarImageBlock'
import { TrustpilotStarRatingBlock } from './TrustpilotStarRatingBlock'
import { BREAKPOINTS } from '../ds/breakpoints'

const fragment = `
  fragment CarouselCardFragment on CarouselCardRecord {
    id
    variant
    padded
    background
    content {
      ...TypographyBlockFragment
      ...SimpleImageBlockFragment
      ...SpacerBlockFragment
      ...TrustpilotStarRatingBlockFragment
      ...AvatarImageBlockFragment
    }
  }

  ${TrustpilotStarRatingBlock.fragment}
  ${AvatarImageBlock.fragment}
`

const StyledCard = styled.div<{
  $variant: CarouselCardRecord['variant']
  $padded: CarouselCardRecord['padded']
  $background: CarouselCardRecord['background']
}>`
  background-color: ${({ $background }) => ($background === 'neutral' ? 'var(--colour-surface-neutral)' : 'none')};
  border-radius: var(--grid-16);
  padding: ${({ $padded }) => ($padded ? 'var(--grid-16)' : 0)};
  box-shadow: 0px 0px 20px 5px hsla(0, 0%, 0%, 0.01);
  max-width: ${({ $variant }) => ($variant === 'small' ? '300px' : '411px')};
  height: 100%;

  @media screen and (max-width: ${BREAKPOINTS.m}) {
    max-width: 300px;
  }
`

const renderBlock = (block: CarouselCardRecord['content'][0]) => {
  switch (block.__typename) {
    case 'TypographyRecord':
      return <TypographyBlock key={block.id} record={block} />
    case 'SimpleImageRecord':
      return <SimpleImageBlock key={block.id} record={block} />
    case 'SpacerRecord':
      return <SpacerBlock key={block.id} record={block} />
    case 'TrustpilotStarRatingRecord':
      return <TrustpilotStarRatingBlock key={block.id} record={block} />
    case 'AvatarImageRecord':
      return <AvatarImageBlock key={block.id} record={block} />
  }
}

export const CarouselCardBlock: FragmentComponent<{}, CarouselCardRecord> = ({ record }) => {
  return (
    <StyledCard $variant={record.variant || 'small'} $padded={record.padded} $background={record.background}>
      {record.content.map(renderBlock)}
    </StyledCard>
  )
}

CarouselCardBlock.fragment = fragment
CarouselCardBlock.recordName = 'CarouselCardRecord'
