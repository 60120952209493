import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { DSText } from '@zoe/ds-web'
import { Logo } from '@/components/shared/Logo'
import { D30Table } from '../blocks/D30Table'

export const NutritionalContentModal = () => {
  return (
    <>
      <Logo colour="dark" />

      <DSSpacer size={24} direction="vertical" />

      <DSText variant="fluid-heading-800">
        Daily 30 Plant <em>Wholefood</em> Prebiotic
      </DSText>
      <DSText variant="fluid-heading-500alt" weight="lotaLight">
        Nutrition facts
      </DSText>

      <DSSpacer size={24} direction="vertical" />

      <DSText variant="fluid-label-100">
        <strong>Daily serving size:</strong> 1 x 15g scoop
      </DSText>
      <DSText variant="fluid-label-100">
        <strong>Servings per bag:</strong> 33
      </DSText>

      <DSSpacer size={24} direction="vertical" />

      <D30Table
        record={{
          __typename: 'TableRecord',
          id: '1',
          table: {
            columns: ['', 'Per 100g', 'Per serving'],
            data: [
              {
                '': '**Energy**',
                'Per 100g': '423 kcal',
                'Per serving': '63 kcal',
              },
              {
                '': '',
                'Per 100g': '1789 KJ',
                'Per serving': '268 KJ',
              },
              {
                '': '**Fat**',
                'Per 100g': '26g',
                'Per serving': '4g',
              },
              {
                '': 'of Which Saturates',
                'Per 100g': '2.6g',
                'Per serving': '0.4g',
              },
              {
                '': '**Carbohydrates**',
                'Per 100g': '18g',
                'Per serving': '3g',
              },
              {
                '': 'Of Which Sugars',
                'Per 100g': '3.2g',
                'Per serving': '0.5g',
              },
              {
                '': '**Fibre**',
                'Per 100g': '30g',
                'Per serving': '5g',
              },
              {
                '': '**Protein**',
                'Per 100g': '18g',
                'Per serving': '3g',
              },
              {
                '': '**Salt**',
                'Per 100g': '0.08g',
                'Per serving': '0.01g',
              },
            ],
          },
        }}
      />

      <D30Table
        record={{
          __typename: 'TableRecord',
          id: '1',
          table: {
            columns: ['Vitamins & Minerals', 'Per Serving', '% RI*'],
            data: [
              {
                'Vitamins & Minerals': '**Copper**',
                'Per Serving': '0.2mg',
                '% RI*': '18%',
              },
              {
                'Vitamins & Minerals': '**Omega 3**',
                'Per Serving': '0.3mg',
                '% RI*': '16%',
              },
            ],
          },
        }}
      />

      <DSText variant="fluid-label-100">* Reference intake of an average adult</DSText>
    </>
  )
}
