import {
  D30_GB_NO_STOCK_WAITLIST_EMAIL_SUBMITTED,
  D30_US_NO_STOCK_WAITLIST_EMAIL_SUBMITTED,
} from '@/lib/braze/brazeConstants'
import { D30Waitlist } from './D30Waitlist'
import { CHECKOUT_GB } from '@/lib/checkout/types'

interface D30NoStockWailtistProps {
  country: string
}

export const D30NoStockWailtist = ({ country }: D30NoStockWailtistProps) => {
  const brazeEventName =
    country === CHECKOUT_GB ? D30_GB_NO_STOCK_WAITLIST_EMAIL_SUBMITTED : D30_US_NO_STOCK_WAITLIST_EMAIL_SUBMITTED
  return (
    <D30Waitlist
      title="We are awaiting new stock"
      subtitle="Sign up to be notified when it's available."
      brazeEventName={brazeEventName}
      country={country}
    />
  )
}
