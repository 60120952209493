import React from 'react'
import styled from 'styled-components'
import { TableRecord } from 'types'

const StyledTable = styled.table`
  margin: 10px 0;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  td,
  th {
    padding: 16px 10px 16px 0;
  }
  td:last-child,
  th:last-child {
    padding-right: 0;
  }
  td:first-child {
    font-size: ${({ theme }) => theme.v2.typography.body.xs.fontSize};
    line-height: ${({ theme }) => theme.v2.typography.body.xs.lineHeight};
  }
  td:not(:first-child),
  th:not(:first-child) {
    text-align: right;
    width: 90px;
  }
  tr {
    border-bottom: 1px solid ${(props) => props.theme.colours.tableBorder};
  }
  tbody tr:last-child {
    border-bottom: none;
  }
  th {
    font-size: ${({ theme }) => theme.v2.typography.body.xxs.fontSize};
    line-height: ${({ theme }) => theme.v2.typography.body.xxs.lineHeight};
  }
  td {
    font-size: ${({ theme }) => theme.v2.typography.body.xxs.fontSize};
    line-height: ${({ theme }) => theme.v2.typography.body.xxs.lineHeight};
    color: ${(props) => props.theme.colours.primary};
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    th {
      font-size: 14px;
      line-height: 20px;
    }
    td {
      font-size: 16px;
      line-height: 24px;
    }
  }
`

const parseText = (text) => {
  return (
    <>
      {text
        .split(/\*\*(.*?)\*\*/g)
        .map((part, index) => (index % 2 === 1 ? <strong key={index}>{part}</strong> : part))}
    </>
  )
}

export const D30Table = ({ record }: { record: TableRecord }) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          {record.table.columns.map((header, hIndex) => (
            <th key={hIndex}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {record.table.data.map((row, rIndex) => (
          <tr key={rIndex}>
            {record.table.columns.map((column, cIndex) => (
              <td key={cIndex}>{parseText(row[column]) || ''}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}
