import { Product } from '@/lib/checkout/types'
import styled from 'styled-components'
import { trackCtaClick } from '@/lib/gtm/gtmV2'
import { formatCurrency } from '@/lib/checkout/utils'
import { DSText } from '@zoe/ds-web'
import { DSLinkButton } from '@/components/ds/LinkButton/LinkButton'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'

type MembershipPriceCardCtaProps = {
  commitmentMonths: number
  footnote?: React.ReactNode
  kit: Product
  membership: Product
  membershipType: 'starter' | 'thrive' | 'thrivePlus'
}

const StyledCta = styled.div`
  padding: 16px;
  background-color: white;
`

export const MembershipPdpPriceCardCta = ({
  commitmentMonths,
  footnote,
  kit,
  membership,
  membershipType,
}: MembershipPriceCardCtaProps) => {
  const buttonLabel = `Choose ${commitmentMonths}-month plan`

  const onClickHandler = () => {
    trackCtaClick('plans', `${buttonLabel}|${membershipType}`)
  }

  const totalCost = membership.price * commitmentMonths + kit.price
  const costPerDay = Math.ceil(totalCost / (commitmentMonths === 12 ? 365 : 120))

  return (
    <StyledCta>
      {totalCost && costPerDay && (
        <DSText variant={'fluid-label-100'} weight="lotaLight" align="center">
          Total cost {formatCurrency(kit.currency, totalCost, true)},{' '}
          <DSText variant={'fluid-label-100'} weight="lotaRegular" as="span">
            equal to {formatCurrency(kit.currency, costPerDay, true)}/day
          </DSText>
        </DSText>
      )}
      {footnote}
      <DSSpacer size={16} direction="vertical" />
      <DSLinkButton
        variant={'primary'}
        colourScheme={'default'}
        size={'large'}
        width={'full'}
        onPress={onClickHandler}
        href={`/checkout/v2?source=membershippdp&product=${kit.zoeProductId}-${membership.zoeProductId}`}
        testId={`membership-price-card-button-${kit.zoeProductId}`}
      >
        {buttonLabel}
      </DSLinkButton>
    </StyledCta>
  )
}
