import { CheckoutCountry, Product, Products } from '@/lib/checkout/types'
import { MembershipPdpPriceCardHeader } from '@/elements/plans/Memberships/MembershipPriceCardPdp/MembershipPdpPriceCardHeader'
import {
  MembershipBenefit,
  MembershipPdpPriceCardBenefits,
} from '@/elements/plans/Memberships/MembershipPriceCardPdp/MembershipPdpPriceCardBenefits'
import { MembershipPdpPriceCardCost } from '@/elements/plans/Memberships/MembershipPriceCardPdp/MembershipPdpPriceCardCost'
import { MembershipPdpPriceCardCta } from '@/elements/plans/Memberships/MembershipPriceCardPdp/MembershipPdpPriceCardCta'
import { MembershipPriceCardProps } from '@/elements/plans/Memberships/MembershipPriceCardPdp/MembershipPdPPriceCard'
import { getCurrentPrices } from '@/elements/plans/Memberships/getCurrentPrices'
import { TextColourType } from '@zoe/ds-web'

interface ChoosePlanOptions extends MembershipPriceCardProps {
  id: string
}

interface PlanConfig {
  name: string
  commitmentMonths: number
  description: string
  bgColour: string
  benefits: MembershipBenefit[]
  tag?: string
  textColour?: TextColourType
}

type ConfigType = Record<'starter' | 'thrive' | 'thrivePlus', PlanConfig>

const createPlanOption = (type: 'starter' | 'thrive' | 'thrivePlus', kit: Product, membership: Product) => {
  const config: ConfigType = {
    starter: {
      name: 'Starter',
      commitmentMonths: 4,
      description: 'You want to boost your health with ZOE with a shorter plan.',
      bgColour: '#E1E2EB',
      benefits: [],
    },
    thrive: {
      name: 'Thrive',
      commitmentMonths: 12,
      description: 'You’re ready to make real lifestyle change and reach your best health.',
      bgColour: '#FFD100',
      benefits: ['ghRestest4m'],
      tag: 'Best Value',
    },
    thrivePlus: {
      name: 'Thrive Plus',
      commitmentMonths: 12,
      description: 'You want proof of positive changes and to stay accountable.',
      textColour: 'primary-inverse',
      bgColour: '#2D3246',
      benefits: ['ghRestest4m', 'ghRestest7m10m'],
    },
  }

  const { name, commitmentMonths, description, bgColour, benefits, tag, textColour } = config[type]

  return {
    id: type,
    header: (
      <MembershipPdpPriceCardHeader
        name={name}
        commitmentMonths={commitmentMonths}
        description={description}
        bgColour={bgColour}
        tag={tag}
        textColour={textColour}
      />
    ),
    benefits: <MembershipPdpPriceCardBenefits additionalBenefits={benefits} />,
    cost: <MembershipPdpPriceCardCost kit={kit} membership={membership} />,
    cta: (
      <MembershipPdpPriceCardCta
        commitmentMonths={commitmentMonths}
        kit={kit}
        membership={membership}
        membershipType={type}
      />
    ),
  }
}

export const selectMembershipPdpOptions = (products: Products, country: CheckoutCountry): ChoosePlanOptions[] => {
  const { kits, memberships } = getCurrentPrices(products)

  const membershipOptionsMap: Record<CheckoutCountry, ChoosePlanOptions[]> = {
    GB: [
      createPlanOption('starter', kits.starter.gb, memberships.starter.gb),
      createPlanOption('thrive', kits.thrive.gb, memberships.thrive.gb),
      createPlanOption('thrivePlus', kits.thrivePlus.gb, memberships.thrivePlus.gb),
    ],
    US: [
      createPlanOption('starter', kits.starter.us, memberships.starter.us),
      createPlanOption('thrive', kits.thrive.us, memberships.thrive.us),
      createPlanOption('thrivePlus', kits.thrivePlus.us, memberships.thrivePlus.us),
    ],
  }

  return membershipOptionsMap[country]
}
