import { DSGrid, GridSurfaceColour } from '@/components/ds/Grid/Grid'
import React from 'react'
import { SpacerRecord } from 'types'
import { GridBlock, GridBlockRecord } from './GridBlock'
import { SpacerBlock } from './Spacer'
import { FragmentComponent } from '@/types/graphql'
import { useExperimentContext } from '@/context/ExperimentContext'
import { useSetVisibility } from '@/lib/hooks/useSetVisibility'

export type GridRecord = {
  __typename: 'GridRecord'
  id?: string
  visibility?: string[]
  backgroundColor?: GridSurfaceColour
  mobileBackgroundColor?: GridSurfaceColour
  rounded?: boolean
  content: (GridBlockRecord | SpacerRecord)[]
  maxWidth?: number
  backgroundFullWidth?: boolean
  dataTestId?: string
}

const fragment = `
  fragment GridFragment on GridRecord {
    __typename
    id
    visibility
    content {
      ...GridBlockFragment
      ...SpacerBlockFragment
    }
    backgroundColor
    mobileBackgroundColor
    rounded
    maxWidth
    backgroundFullWidth
    dataTestId
  }
`

const renderBlock = (record) => {
  switch (record.__typename) {
    case 'GridBlockRecord':
      return <GridBlock key={record.id} record={record} />
    case 'SpacerRecord':
      return <SpacerBlock key={record.id} record={record} />
  }
}

export const Grid: FragmentComponent<{}, GridRecord> = ({ record }) => {
  const { shouldRenderContent } = useExperimentContext()
  const isVisible = useSetVisibility(record?.visibility ?? [])

  if (!shouldRenderContent(record.dataTestId)) {
    return null
  }

  return isVisible ? (
    <DSGrid
      backgroundColor={record?.backgroundColor}
      mobileBackgroundColor={record?.mobileBackgroundColor}
      rounded={record?.rounded}
      maxWidth={record?.maxWidth}
      backgroundFullWidth={record?.backgroundFullWidth}
      dataTestId={record?.dataTestId}
    >
      {record?.content?.map((block, i) => renderBlock(block))}
    </DSGrid>
  ) : null
}

Grid.fragment = fragment
Grid.recordName = 'GridRecord'
