import { responsiveImageBlock } from '@/graphql/utils/commonBlocks'
import React, { useEffect, useState } from 'react'
import { ImageGalleryRecord } from 'types'
import { Image } from 'react-datocms'
import styled from 'styled-components'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/css/pagination'
import { Autoplay, EffectFade, Pagination, Thumbs } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { Swiper as SwiperType } from 'swiper/types'
import { FragmentComponent } from '@/types/graphql'
import { BREAKPOINTS } from '@/components/ds/breakpoints'

const fragment = `
  fragment ImageGalleryBlockFragment on ImageGalleryRecord {
    __typename
    id
    images {
      ${responsiveImageBlock({ width: 620, height: 620, fit: 'fill' })}
    }
    showThumbnailPreviews
    hideControls
  }
`

const StyledGallery = styled.div<{ $viewportWidth: number; $showPagination: boolean; $hideControls: boolean }>`
  max-width: ${({ $viewportWidth }) => `${Math.min($viewportWidth - 32, 620)}px`};
  .swiper {
    ${({ $showPagination }) => $showPagination && `padding-bottom: 6px;`}
  }

  @media (min-width: ${BREAKPOINTS.l}) {
    max-width: 470px;
  }
  @media (min-width: ${BREAKPOINTS.xl}) {
    max-width: 620px;
  }

  width: 100%;

  .main img {
    border-radius: 16px;
    object-fit: cover;
  }

  .swiper-pagination {
    display: block;
    bottom: 46px;
    left: calc(50% - 44px);
    width: 88px;
    padding: 4px 0;
    border-radius: 20px;
    background-color: var(--colour-surface-neutral);
  }

  .swiper-wrapper {
    padding-bottom: ${({ $hideControls }) => ($hideControls ? '0' : 'var(--grid-24)')};
  }

  .swiper-pagination-bullet {
    border-radius: 10px;

    &.swiper-pagination-bullet-active {
      background-color: var(--colour-surface-navy-bold);
    }
  }
`

const StyledSwiper = styled(Swiper)`
  margin: 0 -24px;
  padding: 0 24px;
`

const StyledThumbs = styled.div`
  margin-top: 16px;

  img {
    border-radius: 16px;
    cursor: pointer;
    border: 2px solid transparent;
    object-fit: cover;
    transition: all 0.2s ease-in;
  }

  .active img {
    border-color: ${({ theme }) => theme.v2.surface.yellow};
    object-fit: cover;
  }
`

export const ImageGalleryBlock: FragmentComponent<{}, ImageGalleryRecord> = ({ record }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType>()
  const [activeThumbSlide, setActiveThumbSlide] = useState(0)
  const [mainSwiper, setMainSwiper] = useState<SwiperType>()
  const [width, setWidth] = useState(0)

  const swiperModules = record.showThumbnailPreviews ? [Thumbs, EffectFade] : [Thumbs, EffectFade, Pagination, Autoplay]

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)

    setWidth(window.innerWidth)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <StyledGallery
      $viewportWidth={width}
      $showPagination={!record.showThumbnailPreviews}
      $hideControls={!!record.hideControls}
    >
      <Swiper
        slidesPerView={1}
        thumbs={{ swiper: thumbsSwiper }}
        onSwiper={setMainSwiper}
        onSlideChange={() => setActiveThumbSlide(mainSwiper?.activeIndex ?? 0)}
        modules={swiperModules}
        pagination={!record.hideControls && { clickable: true }}
        effect="slide"
        autoplay={{ delay: 2500 }}
      >
        {record?.images?.map((img, index) => (
          <SwiperSlide key={index} className="main">
            <Image data={img.responsiveImage} />
          </SwiperSlide>
        ))}
      </Swiper>

      {record.showThumbnailPreviews && (
        <StyledThumbs>
          <StyledSwiper
            spaceBetween={4}
            slidesPerView={4}
            onSwiper={setThumbsSwiper}
            modules={[Thumbs]}
            breakpoints={{
              '480': {
                slidesPerView: 4,
                spaceBetween: 14,
              },
            }}
          >
            {record?.images.map((img, index) => (
              <SwiperSlide key={index}>
                <Image data={img.responsiveImage} className={activeThumbSlide === index ? 'active' : ''} />
              </SwiperSlide>
            ))}
          </StyledSwiper>
        </StyledThumbs>
      )}
    </StyledGallery>
  )
}

ImageGalleryBlock.fragment = fragment
ImageGalleryBlock.recordName = 'ImageGalleryRecord'
