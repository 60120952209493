import { D30_NON_PURCHASE_COUNTRY_WAITLIST_EMAIL_SUBMITTED } from '@/lib/braze/brazeConstants'
import { D30Waitlist } from './D30Waitlist'

interface D30NonPurchaseCountryWaitlistProps {
  country: string
}

export const D30NonPurchaseCountryWaitlist = ({ country }: D30NonPurchaseCountryWaitlistProps) => (
  <D30Waitlist
    title="Daily30+ is not available in your country yet"
    subtitle="Be the first to hear when it’s available."
    brazeEventName={D30_NON_PURCHASE_COUNTRY_WAITLIST_EMAIL_SUBMITTED}
    country={country}
  />
)
